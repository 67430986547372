

function Footer() {
    return (
        <div style={{backgroundColor: 'black', textAlign: 'center', padding: '15px'}}>
            <p style={{color: 'lightgray'}}>
                Copyright © 2024 Raminco Maintenance - All rights Reserved
            </p>
        </div>
    );
}

export default Footer;